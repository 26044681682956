import { SidebarCardType, SidebarCardTypeDTO, StoryblokStory } from "@/domain";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  storyblokRelations,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchSidebarCard = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: SidebarCardType) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<StoryblokStory<SidebarCardTypeDTO>[], Error, SidebarCardType>(
    {
      queryKey: [QueryKeys.useFetchSidebarCard],
      queryFn: async () => {
        return storyblokServiceSingleton.getAll<SidebarCardTypeDTO>(
          storyblokRoutes.sidebarCard,
          {
            resolve_relations: [storyblokRelations.sidebarCardMedias],
          }
        );
      },
      select: (data) => {
        const sidebarCard = data.map((story) => story.content)[0];
        const sidebarCardType = SidebarCardType.create(sidebarCard);
        params?.onSuccess?.(sidebarCardType);

        return sidebarCardType;
      },
      ...params?.queryConfig,
    }
  );
};
