import { Achievement } from "@/domain/Achievement";
import { FC } from "react";

type Props = {
  achievement: Achievement;
};

export const AchievementsItem: FC<Props> = ({ achievement }) => {
  return (
    <div className="rounded-xl border border-peat-700 p-4 hover:border-secondary transition-all duration-500 ease-in-out hover:shadow-section-tag mb-7 cursor-pointer gap-4 hover:-translate-y-2">
      <div className="text-lg text-dark-skin">{achievement.issuer}</div>
      <div className="font-semibold text-2xl text-secondary mb-4 mt-2">
        {achievement.title}
      </div>
      <div className="text-base text-peat-700">{achievement.date}</div>
    </div>
  );
};
