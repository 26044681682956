import { NumbersType, NumbersTypeDTO, StoryblokStory } from "@/domain";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  storyblokRelations,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchNumbers = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: NumbersType) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<StoryblokStory<NumbersTypeDTO>[], Error, NumbersType>({
    queryKey: [QueryKeys.useFetchNumbers],
    queryFn: async () => {
      return storyblokServiceSingleton.getAll<NumbersTypeDTO>(
        storyblokRoutes.numbers,
        {
          resolve_relations: [storyblokRelations.numbers],
        }
      );
    },
    select: (data) => {
      const content = data.map((story) => story.content)[0];
      const heroType = NumbersType.create(content);
      params?.onSuccess?.(heroType);

      return heroType;
    },
    ...params?.queryConfig,
  });
};
