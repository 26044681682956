import { ISbRichtext } from "storyblok-js-client";
import {
  StoryblokAssetDto,
  StoryblokStory,
  StoryblokUrlDto,
} from "./Storyblok";
import { Technology, TechnologyDTO } from "./Technology";

export class Project {
  title: string;
  description: ISbRichtext;
  order: number;
  shortDescription: string;
  duration: string;
  link: StoryblokUrlDto;
  image: StoryblokAssetDto;
  type: string;
  keywords: Technology[];

  private constructor(params: ProjectDTO) {
    const keywords = params.keywords.map((keyword) => keyword.content);

    keywords.sort((a, b) => a.order - b.order);

    this.title = params.title;
    this.description = params.description;
    this.order = params.order;
    this.shortDescription = params.shortDescription;
    this.duration = params.duration;
    this.link = params.link;
    this.image = params.image;
    this.type = params.type;
    this.keywords = keywords;
  }

  static create(params: ProjectDTO) {
    return new Project(params);
  }
}

export type ProjectDTO = {
  title: string;
  description: ISbRichtext;
  order: number;
  shortDescription: string;
  duration: string;
  link: StoryblokUrlDto;
  image: StoryblokAssetDto;
  type: string;
  keywords: StoryblokStory<TechnologyDTO>[];
};
