import { Achievement, AchievementDTO } from "@/domain/Achievement";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchAchievements = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: Achievement[]) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<
    StoryblokMultiStoryResult<AchievementDTO>,
    Error,
    Achievement[]
  >({
    queryKey: [QueryKeys.useFetchAchievements],
    queryFn: async () => {
      return storyblokServiceSingleton.get<AchievementDTO>(
        storyblokRoutes.achievements
      );
    },
    select: (data) => {
      const content = data?.data?.stories.map((story) => story.content);
      const volunteeringExperiences = content.map(Achievement.create);

      params?.onSuccess?.(volunteeringExperiences);

      return volunteeringExperiences;
    },
    ...params?.queryConfig,
  });
};
