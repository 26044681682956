import { useFetchWorkExperiencesPage } from "@/hooks/useFetchWorkExperiencesPage";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";
import { WorkExperienceItem } from "./WorkExperienceItem";

export const WorkSection = () => {
  const { data: workExperiences } = useFetchWorkExperiencesPage();

  if (!workExperiences?.length) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.WorkExperiencesPage}>
      <div className="text-title text-dark-skin flex flex-col">
        {workExperiences.map((workExperience) => {
          return (
            <WorkExperienceItem
              key={workExperience.company}
              workExperience={workExperience}
            />
          );
        })}
      </div>
    </SectionWrapper>
  );
};
