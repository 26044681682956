import { MediaItemType, MediaItemTypeDTO } from "./MediaItemType";
import { StoryblokAssetDto } from "./Storyblok";

export class Testimonial {
  content: string;
  image: StoryblokAssetDto;
  issuer: string;
  issuerPosition: string;
  issuerCompany: string;
  socials: MediaItemType[];

  private constructor(params: TestimonialDTO) {
    this.content = params.content;
    this.image = params.image;
    this.issuer = params.issuer;
    this.issuerPosition = params.issuerPosition;
    this.issuerCompany = params.issuerCompany;
    this.socials = params.socials.map(MediaItemType.create);
  }

  static create(params: TestimonialDTO) {
    return new Testimonial(params);
  }
}

export type TestimonialDTO = {
  content: string;
  image: StoryblokAssetDto;
  issuer: string;
  issuerPosition: string;
  issuerCompany: string;
  socials: MediaItemTypeDTO[];
};
