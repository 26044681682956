import { useFetchTechnologies } from "@/hooks/useFetchTechnologies";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { TechnologyTag } from "@/shared/components/TechnologyTag";
import { AnchorEnum } from "@/utils/anchors";

export const TechnologiesSection = () => {
  const { data: technologies } = useFetchTechnologies();

  if (!technologies?.length) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.Technologies}>
      <div className="flex flex-wrap gap-4 mt-5">
        {technologies.map((technology) => (
          <TechnologyTag key={technology.title} technology={technology} />
        ))}
      </div>
    </SectionWrapper>
  );
};
