import { WorkExperience, WorkExperienceDTO } from "@/domain/WorkExperience";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRelations,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchWorkExperiencesPage = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: WorkExperience[]) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<
    StoryblokMultiStoryResult<WorkExperienceDTO>,
    Error,
    WorkExperience[]
  >({
    queryKey: [QueryKeys.useFetchWorkExperiencesPage],
    queryFn: async () => {
      return storyblokServiceSingleton.get<WorkExperienceDTO>(
        storyblokRoutes.workSection,
        {
          resolve_relations: [storyblokRelations.keywords],
        }
      );
    },
    select: (data) => {
      const content = data?.data?.stories.map((story) => story.content);
      const workExperiences = content.map(WorkExperience.create);

      workExperiences.sort((a, b) => a.order - b.order);

      params?.onSuccess?.(workExperiences);

      return workExperiences;
    },
    ...params?.queryConfig,
  });
};
