import yassineLogo from "@/assets/images/logo-yassine.png";
import pdfFile from "@/assets/Yassine_Gabsi_CV.pdf";
import { useFetchSidebarCard } from "@/hooks/useFetchSidebarCard";
import { SocialMediaItem } from "./SocialMediaItem";

export const SidebarCard = () => {
  const { data: sidebarCardContent } = useFetchSidebarCard();

  if (!sidebarCardContent) {
    return null;
  }

  const onButtonClick = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="relative w-full">
      <div className="rounded-4xl py-8 px-6 border border-pale-white bg-transparent w-full hover:shadow-sidebar-card hover:-translate-y-5 transition-all cursor-pointer duration-500 ease-in-out relative  group hover:border-secondary">
        <div className="absolute z-0 top-0 left-0 w-full h-full bg-peat-750 rounded-4xl group-hover:opacity-100 transition-all opacity-0 duration-500 group-hover:backdrop-blur-lg"></div>
        <img
          src={yassineLogo}
          className="absolute h-16 w-16 -top-12 right-0 left-0 mx-auto z-50 transition-all duration-500 group-hover:scale-125"
        />
        <div className="flex flex-col w-full justify-center items-center text-center relative">
          <div className="text-4xl font-bold text-dark-skin">
            {sidebarCardContent.title}
          </div>
          <div className="text-2xl font-semibold text-dark-skin mt-3 text-jost">
            {sidebarCardContent.job}
          </div>
          <div className="overflow-hidden rounded-5xl mb-10 mt-7 w-48 h-48">
            <img
              className="rounded-5xl mb-10 w-48 h-48 object-cover transition-all group-hover:scale-150 duration-500 group-hover:object-top"
              src={sidebarCardContent.image.filename ?? ""}
              alt="YassineGabsi"
            />
          </div>
          <div className="text-2xl text-dark-skin">
            {sidebarCardContent.email}
          </div>
          <div className="text-2xl text-dark-skin mt-2">
            {sidebarCardContent.description}
          </div>
          <div className="my-5 text-peat-700 text-lg">
            {sidebarCardContent.rights}
          </div>
          <div className="gap-3 flex-wrap flex justify-center items-center">
            {sidebarCardContent.mediaItems.map((mediaItem) => {
              return (
                <SocialMediaItem key={mediaItem.link} mediaItem={mediaItem} />
              );
            })}
          </div>
          <button
            className="rounded-full w-full transition-all duration-300 bg-secondary hover:text-white hover:bg-transparent border-secondary py-3 text-xl mt-6 border-2 font-medium"
            onClick={onButtonClick}
          >
            {sidebarCardContent.buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
