import { AnchorEnum } from "@/utils/anchors";
import { ReactNode, createContext, useMemo, useState } from "react";

type VisibleSectionContextProviderProps = {
  children: ReactNode;
};

export type VisibleSectionContextType = {
  visibleSections: AnchorEnum[];
  setVisibleSections: (sections: AnchorEnum[]) => void;
  addVisibleSection: (section: AnchorEnum) => void;
  removeVisibleSection: (section: AnchorEnum) => void;
};

export const VisibleSectionContext = createContext(
  {} as VisibleSectionContextType
);

export function VisibleSectionContextProvider({
  children,
}: VisibleSectionContextProviderProps): ReactNode {
  const [visibleSections, setVisibleSections] = useState<AnchorEnum[]>([
    AnchorEnum.Hero,
  ]);

  const value = useMemo(
    () => ({
      visibleSections,
      setVisibleSections,
      addVisibleSection: (section: AnchorEnum) => {
        setVisibleSections((prevSections) => [...prevSections, section]);
      },
      removeVisibleSection: (section: AnchorEnum) => {
        setVisibleSections((prevSections) =>
          prevSections.filter((prevSection) => prevSection !== section)
        );
      },
    }),
    [visibleSections]
  );

  return (
    <VisibleSectionContext.Provider value={value} key="visibleSectionContext">
      {children}
    </VisibleSectionContext.Provider>
  );
}
