import { useFetchAboutSection } from "@/hooks/useFetchAboutSection";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";

export const About = () => {
  const { data: aboutContent } = useFetchAboutSection();

  if (!aboutContent) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.AboutSection}>
      <div className="text-5xl text-dark-skin">
        <span>{aboutContent.title}</span>{" "}
        <span className="text-jost text-secondary text-5xl-jost">
          {aboutContent.coloredTitle}
        </span>
      </div>

      <div className="text-xl mt-10 text-peat-700">
        {aboutContent.description}
      </div>
    </SectionWrapper>
  );
};
