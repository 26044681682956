import { Technology } from "@/domain/Technology";
import { cn } from "@/utils";
import { FC } from "react";
import { ReactSVG } from "react-svg";

type Props = {
  technology: Technology;
  size?: "small" | "normal";
};

export const TechnologyTag: FC<Props> = ({ technology, size = "normal" }) => {
  return (
    <div
      className={cn(
        "flex flex-row gap-2 rounded-full lg:px-6 lg:py-2 px-4 py-1 text-black font-medium text-lg bg-secondary border border-secondary duration-500 transition-all ease-in-out hover:bg-transparent hover:text-secondary w-fit items-center justify-center cursor-pointer group/technology",
        size === "small" &&
          "px-3 py-1 text-sm backdrop-blur-sm bg-opacity-50 bg-white border-none hover:bg-secondary hover:text-white"
      )}
    >
      <ReactSVG
        src={technology.icon.filename.replace(
          "//a.storyblok.com",
          "//a2.storyblok.com"
        )}
        className={cn(
          "h-6 w-6 fill-black hover:fill-secondary ease-in-out group-hover/technology:fill-secondary",
          size === "small" && "h-4 w-4 group-hover/technology:fill-white"
        )}
        wrapper="span"
      />
      {technology.title}
    </div>
  );
};
