import "swiper/css";
import "swiper/css/autoplay";

import { useFetchLanguages } from "@/hooks/useFetchLanguages";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";
import { LanguageItem } from "./LanguageItem";

export const LanguagesSection = () => {
  const { data: languages } = useFetchLanguages();

  if (!languages?.length) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.Languages}>
      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
        {languages.map((language) => {
          return <LanguageItem key={language.title} language={language} />;
        })}
      </div>
    </SectionWrapper>
  );
};
