export enum QueryKeys {
  useFetchSidebarCard = "useFetchSidebarCard",
  useFetchHero = "useFetchHero",
  useFetchAboutSection = "useFetchAboutSection",
  useFetchNumbers = "useFetchNumbers",
  useFetchWorkExperiencesPage = "useFetchWorkExperiencesPage",
  useFetchStudyExperiences = "useFetchStudyExperiences",
  useFetchVolunteeringExperiences = "useFetchVolunteeringExperiences",
  useFetchAchievements = "useFetchAchievements",
  useFetchTechnologies = "useFetchTechnologies",
  useFetchTestimonials = "useFetchTestimonials",
  useFetchLanguages = "useFetchLanguages",
  useFetchProjects = "useFetchProjects",
}
