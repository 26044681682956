export class AboutType {
  title: string;
  coloredTitle: string;
  description: string;

  private constructor(params: AboutTypeDTO) {
    this.title = params.title;
    this.coloredTitle = params.coloredTitle;
    this.description = params.description;
  }

  static create(params: AboutTypeDTO) {
    return new AboutType(params);
  }
}

export type AboutTypeDTO = {
  title: string;
  coloredTitle: string;
  description: string;
};
