import { StoryblokAssetDto } from "./Storyblok";

export class MediaItemType {
  link: string;
  icon: StoryblokAssetDto;

  constructor(params: MediaItemTypeDTO) {
    this.link = params.link;
    this.icon = params.icon;
  }

  static create(params: MediaItemTypeDTO) {
    return new MediaItemType(params);
  }
}

export type MediaItemTypeDTO = {
  link: string;
  icon: StoryblokAssetDto;
};
