import { useFetchVolunteeringExperiences } from "@/hooks/useFetchVolunteeringExperiences";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";
import { WorkExperienceItem } from "../WorkSection/WorkExperienceItem";

export const VolunteeringSection = () => {
  const { data: volunteeringExperiences } = useFetchVolunteeringExperiences();

  if (!volunteeringExperiences?.length) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.VolunteeringExperiences}>
      <div className="text-title text-dark-skin flex flex-col">
        {volunteeringExperiences.map((volunteeringExperience) => {
          return (
            <WorkExperienceItem
              key={volunteeringExperience.company}
              workExperience={volunteeringExperience}
            />
          );
        })}
      </div>
    </SectionWrapper>
  );
};
