import { useFetchAchievements } from "@/hooks/useFetchAchievements";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";
import { AchievementsItem } from "./AchievementsItem";

export const AchievementsSection = () => {
  const { data: achievements } = useFetchAchievements();

  if (!achievements?.length) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.Achievements}>
      <div className="text-title text-dark-skin grid lg:grid-cols-3 grid-cols-1 gap-4">
        {achievements.map((achievement) => {
          return (
            <AchievementsItem
              key={achievement.title}
              achievement={achievement}
            />
          );
        })}
      </div>
    </SectionWrapper>
  );
};
