import { cn } from "@/utils";
import { PropsWithClassName } from "@/utils/types";
import { FC } from "react";
import { IconType } from "react-icons";

type Props = {
  title: string;
  Icon: IconType;
};

export const SectionTag: FC<PropsWithClassName<Props>> = ({
  title,
  Icon,
  className,
}) => {
  return (
    <div
      className={cn(
        "rounded-full px-4 py-2 border-pale-white border flex w-fit items-center gap-2 justify-center transition-all duration-500 hover:border-secondary group hover:-translate-y-1 ease-in-out hover:shadow-section-tag mb-7 cursor-pointer",
        className
      )}
    >
      <Icon className="transition-all duration-500 h-4 w-4 text-dark-skin group-hover:text-secondary" />
      <div className="transition-all duration-500 text-sm text-dark-skin group-hover:text-secondary">
        {title.toLocaleUpperCase()}
      </div>
    </div>
  );
};
