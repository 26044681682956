import "./App.css";
import { About } from "./sections/About/About";
import { AchievementsSection } from "./sections/AchievementsSection/AchievementsSection";
import { Hero } from "./sections/Hero/Hero";
import { LanguagesSection } from "./sections/LanguagesSection/LanguagesSection";
import { NumbersSection } from "./sections/Numbers/NumbersSection";
import { ProjectsSection } from "./sections/ProjectsSection/ProjectsSection";
import { RightSidebar } from "./sections/Sidebar/RightSidebar";
import { Sidebar } from "./sections/Sidebar/Sidebar";
import { SidebarCard } from "./sections/Sidebar/SidebarCard";
import { StudySection } from "./sections/StudySection/StudySection";
import { TechnologiesSection } from "./sections/TechnologiesSection/TechnologiesSection";
import { TestimonialsSection } from "./sections/TestimonialsSection/TestimonialsSection";
import { VolunteeringSection } from "./sections/VolunteeringSection/VolunteeringSection";
import { WorkSection } from "./sections/WorkSection/WorkSection";

function App() {
  return (
    <>
      <Sidebar />
      <div className="lg:ml-sidebar py-28 px-6 flex flex-col gap-32 lg:mr-right-sidebar">
        <Hero />

        <div className="lg:hidden flex justify-center">
          <SidebarCard />
        </div>

        <NumbersSection />

        <About />

        <StudySection />

        <WorkSection />

        <VolunteeringSection />

        <AchievementsSection />

        <TechnologiesSection />

        <ProjectsSection />

        <TestimonialsSection />

        <LanguagesSection />
      </div>

      <RightSidebar />
    </>
  );
}

export default App;
