import { Project } from "@/domain/Project";
import { storyblokServiceSingleton } from "@/services/storyblok.service";
import { Modal } from "@/shared/components/Modal";
import { TechnologyTag } from "@/shared/components/TechnologyTag";
import { cn } from "@/utils";
import { FC, useEffect, useRef, useState } from "react";

type Props = {
  project: Project;
};

export const ProjectItem: FC<Props> = ({ project }) => {
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (textRef.current && textRef.current.clientHeight) {
      setDescriptionHeight(textRef.current.clientHeight);
    }
  }, [project.shortDescription]);

  const createMarkup = () => {
    return {
      __html: storyblokServiceSingleton.instance.richTextResolver.render(
        project.description
      ),
    };
  };

  const textRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        className="rounded-3xl flex flex-col relative h-96 bg-center bg-cover bg-no-repeat cursor-pointer hover:shadow-section-tag hover:-translate-y-5 duration-500 transition-all ease-in-out group/project-card hover:border-secondary overflow-hidden border border-peat-700"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setShowModal(true)}
      >
        <div>
          <img
            src={project.image.filename}
            className="overflow-hidden object-cover object-center group-hover/project-card:scale-110 transition-all duration-500 ease-in-out h-full w-full bottom-0 top-0 absolute left-0 right-0"
          />
        </div>
        <div className="bg-black w-full h-full absolute top-0 right-0 left-0 bottom-0 opacity-25 group-hover/project-card:opacity-40 transition-all duration-500 ease-in-out overflow-hidden" />
        <div
          className={cn(
            "absolute bottom-0 left-0 right-0 z-0 h-24 bg-project-card transition-all duration-500 group-hover/project-card:h-1/2 group-hover/project-card:opacity-100"
          )}
        />
        <div className="top-4 absolute left-4 right-4">
          <div className="flex flex-wrap gap-4">
            {project.keywords.map((keyword) => (
              <TechnologyTag
                key={keyword.title}
                technology={keyword}
                size="small"
              />
            ))}
          </div>
        </div>
        <div className="absolute bottom-0 left-4 right-4 ">
          <div className="flex flex-row items-center justify-between mb-3">
            <div className="text-2xl text-dark-skin font-medium">
              {project.title}
            </div>
            <div
              className={cn(
                "flex flex-row gap-2 rounded-full px-6 py-2 text-black font-medium text-lg bg-secondary border border-secondary duration-500 transition-all ease-in-out hover:bg-transparent hover:text-secondary w-fit items-center justify-center cursor-pointer group/technology",
                "px-3 py-1 text-sm backdrop-blur-sm bg-opacity-50 bg-white border-none hover:bg-secondary hover:text-white"
              )}
            >
              {project.type}
            </div>
          </div>

          <div
            ref={textRef}
            style={{
              height: descriptionHeight
                ? isHovered
                  ? descriptionHeight
                  : "0px"
                : "auto",
            }}
            className={cn(
              "mb-0 line-clamp-3 opacity-0 transition-all duration-500 group-hover/project-card:opacity-100 text-base text-peat-300 group-hover/project-card:mb-4"
            )}
          >
            <div className="text-sm text-secondary">{project.duration}</div>
            {project.shortDescription}
          </div>
        </div>
      </div>
      <Modal open={showModal} setOpen={setShowModal}>
        <div className="font-sans text-b2 text-text-h3 text-left font-light flex flex-col relative items-start px-8 overflow-y-auto max-h-[80vh] pb-10 scrollbar-hide">
          {!!project.link.url && (
            <a
              className="rounded-full w-fit transition-all duration-300 bg-secondary hover:text-white hover:bg-transparent border-secondary py-1 px-6 text-base mb-4 border-2 font-medium"
              href={project.link.url}
              target="_blank"
            >
              Visit
            </a>
          )}

          <div className="text-3xl text-dark-skin font-medium text-center">
            {project.title}
          </div>

          <div className="w-full flex items-center justify-center my-5">
            <img
              className="border-secondary border rounded-2xl object-cover object-center max-h-96 w-full"
              src={project.image.filename}
            />
          </div>
          <div className="text-xl text-secondary font-medium">
            {project.duration}
          </div>

          <div
            className="text-lg mt-5 storyblok-markup text-dark-skin"
            dangerouslySetInnerHTML={createMarkup()}
          />

          <div className="flex flex-wrap gap-4 mt-5">
            {project.keywords.map((keyword) => (
              <TechnologyTag
                key={keyword.title}
                technology={keyword}
                size="small"
              />
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};
