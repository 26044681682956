import { StoryblokStory } from "@/domain/Storyblok";
import type { ISbResult, ISbStoriesParams } from "storyblok-js-client";
import StoryblokClient from "storyblok-js-client";
import { loggerService } from "./logger.service";
import { StoryblokFailure } from "./storyblok.failure";

export interface StoryblokMultiStoryData<T> {
  stories: StoryblokStory<T>[];
}

export interface StoryblokMultiStoryResult<T> extends ISbResult {
  data: StoryblokMultiStoryData<T>;
}

export type StoryblokVersion = "published" | "draft";

export const storyblokBaseSlug = "cdn/stories" as const;

class StoryblokService {
  private storyblok: StoryblokClient;

  constructor() {
    this.storyblok = new StoryblokClient({
      accessToken: process.env.VITE_STORYBLOK_ACCESS_TOKEN,
    });
  }

  public get instance(): StoryblokClient {
    return this.storyblok;
  }

  public async get<T>(
    key: string,
    storyblokParams?: ISbStoriesParams
  ): Promise<StoryblokMultiStoryResult<T>> {
    try {
      loggerService.log(`[CMS] Fetching storyblok data for key: ${key}`);
      const results = [];
      let page = 1;
      let allFetched = false;

      do {
        const currentPageResult = await this.storyblok.get(storyblokBaseSlug, {
          starts_with: key,
          per_page: 100,
          page: page,
          ...storyblokParams,
        });

        results.push(...currentPageResult.data.stories);

        if (currentPageResult.total === results.length) {
          allFetched = true;
          break;
        }
        page++;
      } while (!allFetched);

      const finalResult = {
        data: {
          stories: results,
        },
      } as StoryblokMultiStoryResult<T>;
      loggerService.log(
        `[CMS] Successfully fetched storyblok data for key: ${key}`
      );
      return finalResult as unknown as Promise<StoryblokMultiStoryResult<T>>;
    } catch (error) {
      loggerService.error(
        `[CMS] Error fetching storyblok data for key: ${key}`
      );
      throw new StoryblokFailure(
        "StoryblokService",
        `Error fetching storyblok data for key: ${key}. Error: ${error}`
      );
    }
  }

  public async getAll<T>(
    key: string,
    filterQuery = {},
    resolveRelations: string[] = []
  ): Promise<StoryblokStory<T>[]> {
    try {
      loggerService.log(`[CMS] Fetching storyblok data for key: ${key}`);
      const result = await this.storyblok.getAll(storyblokBaseSlug, {
        starts_with: key,
        filter_query: filterQuery,
        resolve_relations: resolveRelations.join(","),
      });
      loggerService.log(
        `[CMS] Successfuly fetch storyblok data for key: ${key}`
      );

      return result as unknown as Promise<StoryblokStory<T>[]>;
    } catch (error) {
      loggerService.error(
        `[CMS] Error fetching storyblok data for key: ${key}`
      );
      throw new StoryblokFailure(
        "StoryblokService",
        `Error fetching storyblok data for key: ${key}. Error: ${error}`
      );
    }
  }
}

export enum storyblokRoutes {
  sidebarCard = "sidebar-card",
  hero = "hero",
  about = "about",
  numbers = "numbers",
  workSection = "work-experiences",
  studySection = "study-experiences",
  volunteeringSection = "volunteering-experiences",
  achievements = "achievements",
  technologies = "technologies",
  testimonials = "testimonials",
  languages = "languages",
  projects = "projects",
}

export const storyblokRelations = {
  sidebarCardMedias: "sidebar-card.medias",
  numbers: "numbers.numbers",
  keywords: "work-experience.keywords",
  projectsKeywords: "project.keywords",
};

export const storyblokServiceSingleton = new StoryblokService();

export default StoryblokService;
