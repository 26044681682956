import { StoryblokAssetDto } from "./Storyblok";

export class Technology {
  title: string;
  icon: StoryblokAssetDto;
  order: number;

  private constructor(params: TechnologyDTO) {
    this.title = params.title;
    this.icon = params.icon;
    this.order = params.order;
  }

  static create(params: TechnologyDTO) {
    return new Technology(params);
  }
}

export type TechnologyDTO = {
  title: string;
  icon: StoryblokAssetDto;
  order: number;
};
