export class Achievement {
  title: string;
  date: string;
  issuer: string;

  private constructor(params: AchievementDTO) {
    this.title = params.title;
    this.date = params.date;
    this.issuer = params.issuer;
  }

  static create(params: AchievementDTO) {
    return new Achievement(params);
  }
}

export type AchievementDTO = {
  title: string;
  date: string;
  issuer: string;
};
