import { useVisibleSectionContext } from "@/hooks/useVisibleSectionContext";
import { loggerService } from "@/services/logger.service";
import { AnchorEnum, anchors } from "@/utils/anchors";
import { PropsWithClassNameAndChildren } from "@/utils/types";
import { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { SectionTag } from "./SectionTag";

type Props = {
  anchor: AnchorEnum;
};
export const SectionWrapper: FC<PropsWithClassNameAndChildren<Props>> = ({
  anchor,
  children,
  className,
}) => {
  const { removeVisibleSection, addVisibleSection } =
    useVisibleSectionContext();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const anchorType = anchors.find(
    (anchorContent) => anchorContent.anchor === anchor
  );

  if (!anchorType) {
    loggerService.error(`Anchor ${anchor} not found`);
    return null;
  }

  useEffect(() => {
    if (inView) {
      addVisibleSection(anchor);
    } else {
      removeVisibleSection(anchor);
    }
  }, [inView]);

  return (
    <section id={anchor} className={className} ref={ref}>
      <SectionTag title={anchorType.tagTitle} Icon={anchorType.Icon} />

      {!!anchorType.title && (
        <div className="text-5xl text-dark-skin mb-10">{anchorType.title}</div>
      )}

      {children}
    </section>
  );
};
