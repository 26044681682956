import { IconType } from "react-icons";
import { BsChatSquareTextFill, BsPersonFill } from "react-icons/bs";
import { FaCodeCompare, FaUserGraduate } from "react-icons/fa6";
import { GiAchievement } from "react-icons/gi";
import { GrTechnology } from "react-icons/gr";
import { IoCodeWorkingSharp, IoLanguage } from "react-icons/io5";
import { MdNumbers, MdVolunteerActivism } from "react-icons/md";
import { RiHome3Line } from "react-icons/ri";

export enum AnchorEnum {
  Hero = "Hero",
  AboutSection = "AboutSection",
  Numbers = "Numbers",
  WorkExperiencesPage = "WorkExperiencesPage",
  StudyExperiences = "StudyExperiences",
  VolunteeringExperiences = "VolunteeringExperiences",
  Achievements = "Achievements",
  Technologies = "Technologies",
  Testimonials = "Testimonials",
  Languages = "Languages",
  Projects = "Projects",
}

export type AnchorType = {
  anchor: AnchorEnum;
  tagTitle: string;
  title?: string;
  Icon: IconType;
};
export const anchors: AnchorType[] = [
  { anchor: AnchorEnum.Hero, Icon: RiHome3Line, tagTitle: "Introduction" },
  { anchor: AnchorEnum.Numbers, Icon: MdNumbers, tagTitle: "Numbers" },
  { anchor: AnchorEnum.AboutSection, Icon: BsPersonFill, tagTitle: "About me" },
  {
    anchor: AnchorEnum.StudyExperiences,
    Icon: FaUserGraduate,
    tagTitle: "Education",
    title: "Studies",
  },
  {
    anchor: AnchorEnum.WorkExperiencesPage,
    Icon: IoCodeWorkingSharp,
    tagTitle: "Professional Experience",
    title: "Work Experience",
  },
  {
    anchor: AnchorEnum.VolunteeringExperiences,
    Icon: MdVolunteerActivism,
    tagTitle: "Volunteering",
    title: "Volunteering Experiences",
  },
  {
    anchor: AnchorEnum.Achievements,
    Icon: GiAchievement,
    tagTitle: "Achievements",
    title: "Achievements",
  },
  {
    anchor: AnchorEnum.Technologies,
    Icon: GrTechnology,
    tagTitle: "Skills",
    title: "Technologies",
  },
  {
    anchor: AnchorEnum.Projects,
    Icon: FaCodeCompare,
    tagTitle: "Portfolio",
    title: "Projects",
  },
  {
    anchor: AnchorEnum.Testimonials,
    Icon: BsChatSquareTextFill,
    tagTitle: "Testimonials",
    title: "Testimonials",
  },
  {
    anchor: AnchorEnum.Languages,
    Icon: IoLanguage,
    tagTitle: "Languages",
    title: "Languages",
  },
];
