import { Testimonial, TestimonialDTO } from "@/domain/Testimonial";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchTestimonials = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: Testimonial[]) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<
    StoryblokMultiStoryResult<TestimonialDTO>,
    Error,
    Testimonial[]
  >({
    queryKey: [QueryKeys.useFetchTestimonials],
    queryFn: async () => {
      return storyblokServiceSingleton.get<TestimonialDTO>(
        storyblokRoutes.testimonials
      );
    },
    select: (data) => {
      const content = data?.data?.stories.map((story) => story.content);
      const testimonials = content.map(Testimonial.create);

      params?.onSuccess?.(testimonials);

      return testimonials;
    },
    ...params?.queryConfig,
  });
};
