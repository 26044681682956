import { AboutType, AboutTypeDTO, StoryblokStory } from "@/domain";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchAboutSection = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: AboutType) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<StoryblokStory<AboutTypeDTO>[], Error, AboutType>({
    queryKey: [QueryKeys.useFetchAboutSection],
    queryFn: async () => {
      return storyblokServiceSingleton.getAll<AboutTypeDTO>(
        storyblokRoutes.about
      );
    },
    select: (data) => {
      const hero = data.map((story) => story.content)[0];
      const heroType = AboutType.create(hero);
      params?.onSuccess?.(heroType);

      return heroType;
    },
    ...params?.queryConfig,
  });
};
