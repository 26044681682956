import { Language, LanguageDTO } from "@/domain/Language";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchLanguages = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: Language[]) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<StoryblokMultiStoryResult<LanguageDTO>, Error, Language[]>({
    queryKey: [QueryKeys.useFetchLanguages],
    queryFn: async () => {
      return storyblokServiceSingleton.get<LanguageDTO>(
        storyblokRoutes.languages
      );
    },
    select: (data) => {
      const content = data?.data?.stories.map((story) => story.content);
      const languages = content.map(Language.create);

      languages.sort((a, b) => a.order - b.order);

      params?.onSuccess?.(languages);

      return languages;
    },
    ...params?.queryConfig,
  });
};
