import { useFetchTestimonials } from "@/hooks/useFetchTestimonials";
import "swiper/css";
import "swiper/css/autoplay";

import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { TestimonialItem } from "./TestimonialItem";

export const TestimonialsSection = () => {
  const { data: testimonials } = useFetchTestimonials();

  if (!testimonials?.length) {
    return null;
  }

  const isMobile = window.innerWidth < 1024;

  return (
    <SectionWrapper anchor={AnchorEnum.Testimonials}>
      <div className="text-title text-dark-skin ">
        <Swiper
          modules={[Autoplay]}
          slidesPerView={isMobile ? 1 : 2}
          spaceBetween={24}
          className="h-auto"
          autoplay={{ delay: 3000 }}
        >
          {testimonials.map((testimonial) => {
            return (
              <SwiperSlide key={testimonial.issuer} className="!h-auto">
                <TestimonialItem
                  key={testimonial.issuer}
                  testimonial={testimonial}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </SectionWrapper>
  );
};
