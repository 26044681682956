export class NumbersType {
  title: string;
  numbers: {
    number: string;
    description: string;
  }[];

  private constructor(params: NumbersTypeDTO) {
    this.title = params.title;
    this.numbers = params.numbers;
  }

  static create(params: NumbersTypeDTO) {
    return new NumbersType(params);
  }
}

export type NumbersTypeDTO = {
  title: string;
  numbers: {
    number: string;
    description: string;
  }[];
};
