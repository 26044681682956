import { ISbRichtext } from "storyblok-js-client";
import { StoryblokStory, StoryblokUrlDto } from "./Storyblok";
import { Technology, TechnologyDTO } from "./Technology";

export class WorkExperience {
  position: string;
  company: string;
  companyUrl: StoryblokUrlDto;
  location: string;
  duration: string;
  description: ISbRichtext;
  order: number;
  keywords: Technology[];

  private constructor(params: WorkExperienceDTO) {
    const keywords = params.keywords.map((keyword) => keyword.content);

    keywords.sort((a, b) => a.order - b.order);

    this.position = params.position;
    this.company = params.company;
    this.companyUrl = params.companyUrl;
    this.location = params.location;
    this.duration = params.duration;
    this.description = params.description;
    this.order = params.order;
    this.keywords = keywords;
  }

  static create(params: WorkExperienceDTO) {
    return new WorkExperience(params);
  }
}

export type WorkExperienceDTO = {
  position: string;
  company: string;
  companyUrl: StoryblokUrlDto;
  location: string;
  duration: string;
  description: ISbRichtext;
  order: number;
  keywords: StoryblokStory<TechnologyDTO>[];
};
