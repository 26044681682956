import { WorkExperience } from "@/domain/WorkExperience";
import { storyblokServiceSingleton } from "@/services/storyblok.service";
import { TechnologyTag } from "@/shared/components/TechnologyTag";
import { FC } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { LuExternalLink } from "react-icons/lu";

type Props = {
  workExperience: WorkExperience;
};

export const WorkExperienceItem: FC<Props> = ({ workExperience }) => {
  const createMarkup = () => {
    return {
      __html: storyblokServiceSingleton.instance.richTextResolver.render(
        workExperience.description
      ),
    };
  };

  return (
    <div className="flex flex-row relative pb-20 group last:pb-5">
      <div className="h-full w-0.5 absolute rounded-full bg-peat-700 left-0 ml-2 transition-all duration-500 group-hover:bg-secondary" />
      <div className="bg-peat-700 rounded-full w-4 h-4 absolute top-0 transition-all duration-500 group-hover:bg-secondary" />
      <div className="flex-1 pl-14 -mt-2">
        <div className="text-xl text-peat-700">{workExperience.duration}</div>
        <div className="text-3xl text-secondary mt-4 font-bold">
          {workExperience.position}
        </div>
        <div className="h-0.5 w-[5%] bg-peat-700 rounded-full my-5 transition-all duration-500 group-hover:bg-secondary"></div>
        {workExperience.companyUrl.url ? (
          <a
            href={workExperience.companyUrl.url}
            target="_blank"
            className="text-xl text-dark-skin flex flex-row items-center gap-2 hover:text-secondary duration-500 transition-all w-fit"
          >
            {workExperience.company}
            {workExperience.companyUrl.url && <LuExternalLink />}
          </a>
        ) : (
          <div className="text-xl text-dark-skin flex flex-row items-center gap-2 hover:text-secondary duration-500 transition-all w-fit">
            {workExperience.company}
          </div>
        )}
        <div className="text-lg text-peat-700 flex flex-row items-center gap-2">
          <FaLocationDot />
          {workExperience.location}
        </div>
        <div
          className="text-lg mt-5 storyblok-markup text-dark-skin"
          dangerouslySetInnerHTML={createMarkup()}
        />

        {!!workExperience.keywords.length && (
          <div className="flex flex-wrap gap-4 mt-5">
            {workExperience.keywords.map((keyword) => (
              <TechnologyTag key={keyword.title} technology={keyword} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
