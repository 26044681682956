import { useFetchHero } from "@/hooks/useFetchHero";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";
import { MdWavingHand } from "react-icons/md";

export const Hero = () => {
  const { data: heroContent } = useFetchHero();

  if (!heroContent) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.Hero}>
      <div className="lg:text-title text-dark-skin text-6xl">
        <MdWavingHand className="text-secondary h-12 w-12" />
        <span>{heroContent.title.split("{value}")[0]}</span>
        <span className="text-jost text-secondary text-title-jost">
          {heroContent.name}
        </span>
        <span>{heroContent.title.split("{value}")[1]}</span>
      </div>

      <div className="text-xl mt-10 text-peat-700">
        {heroContent.description}
      </div>
    </SectionWrapper>
  );
};
