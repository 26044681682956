export class HeroType {
  title: string;
  name: string;
  description: string;

  private constructor(params: HeroTypeDTO) {
    this.title = params.title;
    this.name = params.name;
    this.description = params.description;
  }

  static create(params: HeroTypeDTO) {
    return new HeroType(params);
  }
}

export type HeroTypeDTO = {
  title: string;
  name: string;
  description: string;
};
