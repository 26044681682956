import { useFetchStudyExperiences } from "@/hooks/useFetchStudyExperiences";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";
import { WorkExperienceItem } from "../WorkSection/WorkExperienceItem";

export const StudySection = () => {
  const { data: studyExperiences } = useFetchStudyExperiences();

  if (!studyExperiences?.length) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.StudyExperiences}>
      <div className="text-title text-dark-skin flex flex-col">
        {studyExperiences.map((studyExperience) => {
          return (
            <WorkExperienceItem
              key={studyExperience.company}
              workExperience={studyExperience}
            />
          );
        })}
      </div>
    </SectionWrapper>
  );
};
