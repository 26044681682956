export class Language {
  title: string;
  level: string;
  order: number;

  private constructor(params: LanguageDTO) {
    this.title = params.title;
    this.level = params.level;
    this.order = params.order;
  }

  static create(params: LanguageDTO) {
    return new Language(params);
  }
}

export type LanguageDTO = {
  title: string;
  level: string;
  order: number;
};
