import { useVisibleSectionContext } from "@/hooks/useVisibleSectionContext";
import { cn } from "@/utils";
import { AnchorEnum, anchors } from "@/utils/anchors";
import { useState } from "react";
import { IoSettingsSharp } from "react-icons/io5";

export const RightSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(true);

  const { visibleSections, setVisibleSections } = useVisibleSectionContext();

  const scrollToAnchor = (anchor: AnchorEnum) => {
    const element = document.querySelector("#" + anchor);

    if (!element) {
      return;
    }

    const topPos = element.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
      top: topPos,
      behavior: "smooth",
    });

    setVisibleSections([anchor]);
  };

  const firstVisibleSection = visibleSections[0];

  const isMobile = window.innerWidth < 1024;

  return (
    <div className="fixed right-0 top-0 bottom-0 z-40 h-screen w-right-sidebar text-white px-8 flex items-center justify-center mr-2 lg:mb-0">
      {isMobile && (
        <div className="absolute top-40 bg-black py-2 pl-2 pr-4 -right-2 rounded-l-md">
          <IoSettingsSharp
            className=" w-9 h-9 fill-secondary "
            onClick={() => setShowSidebar((prev) => !prev)}
          />
        </div>
      )}

      <div
        className={cn(
          "flex flex-col gap-4 border border-peat-700 p-3 rounded-full lg:bg-transparent bg-black relative transition-all duration-500 ease-in-out",
          !showSidebar && "translate-x-20"
        )}
      >
        {anchors.map((anchor) => {
          return (
            <button
              onClick={() => scrollToAnchor(anchor.anchor)}
              key={anchor.anchor}
              className={cn(
                "flex flex-col items-center justify-center gap-4 text-peat-700 duration-500 transition-all ease-in-out",
                {
                  "text-secondary": anchor.anchor === firstVisibleSection,
                }
              )}
            >
              <anchor.Icon className="lg:h-6 lg:w-6 w-7 h-7" />
            </button>
          );
        })}
      </div>
    </div>
  );
};
