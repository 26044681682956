import { Testimonial } from "@/domain/Testimonial";
import { FC } from "react";
import { SocialMediaItem } from "../Sidebar/SocialMediaItem";

type Props = {
  testimonial: Testimonial;
};

export const TestimonialItem: FC<Props> = ({ testimonial }) => {
  return (
    <div className="rounded-4xl border border-peat-700 p-10 flex flex-col gap-6 h-full cursor-pointer hover:shadow-section-tag hover:-translate-y-5 duration-500 transition-all ease-in-out hover:border-secondary justify-between items-start">
      <div className="flex flex-col gap-5">
        <div className="flex flex-row items-center gap-5">
          <img
            src={testimonial.image.filename}
            alt={testimonial.issuer}
            className="h-14 w-14 rounded-full"
          />
          <div className="flex flex-col">
            <div className="text-2xl text-dark-skin font-medium">
              {testimonial.issuer}
            </div>
            <div className="text-dark-skin text-lg">
              {testimonial.issuerPosition} at{" "}
              <span className="text-secondary font-medium">
                {testimonial.issuerCompany}
              </span>
            </div>
          </div>
        </div>

        <div className="text-xl text-dark-skin">"{testimonial.content}"</div>
      </div>
      <div className="gap-3 flex-wrap flex justify-center items-center">
        {testimonial.socials.map((mediaItem) => (
          <SocialMediaItem
            key={mediaItem.link}
            mediaItem={mediaItem}
            className="p-2"
            iconClassName="h-5 w-5"
          />
        ))}
      </div>
    </div>
  );
};
