import { useFetchNumbers } from "@/hooks/useFetchNumbers";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";

export const NumbersSection = () => {
  const { data: content } = useFetchNumbers();

  if (!content) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.Numbers}>
      <div className="grid lg:grid-cols-3 grid-cols-2 gap-6">
        {content.numbers.map((number) => {
          return (
            <div key={number.number} className="text-dark-skin flex flex-col">
              <span className="text-6xl text-secondary">{number.number}</span>
              <span className="text-dark-skin text-3xl">
                {number.description}
              </span>
            </div>
          );
        })}
      </div>
    </SectionWrapper>
  );
};
