import { useFetchProjects } from "@/hooks/useFetchProjects";
import { SectionWrapper } from "@/shared/components/SectionWrapper";
import { AnchorEnum } from "@/utils/anchors";
import { ProjectItem } from "./ProjectItem";

export const ProjectsSection = () => {
  const { data: projects } = useFetchProjects();

  if (!projects?.length) {
    return null;
  }

  return (
    <SectionWrapper anchor={AnchorEnum.Projects}>
      <div className="text-title text-dark-skin grid lg:grid-cols-2 grid-cols-1 gap-x-4 gap-y-10">
        {projects.map((project) => {
          return <ProjectItem key={project.title} project={project} />;
        })}
      </div>
    </SectionWrapper>
  );
};
