import { Language } from "@/domain/Language";
import { FC } from "react";

type Props = {
  language: Language;
};

export const LanguageItem: FC<Props> = ({ language }) => {
  return (
    <div className="rounded-2xl border border-peat-700 p-6 flex flex-col gap-4 h-full cursor-pointer hover:shadow-section-tag hover:-translate-y-2 duration-500 transition-all ease-in-out hover:border-secondary justify-between items-center">
      <div className="text-secondary text-2xl text-center">
        {language.title}
      </div>
      <div className="text-dark-skin text-lg text-center">{language.level}</div>
    </div>
  );
};
