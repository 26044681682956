import App from "@/App";
import { VisibleSectionContextProvider } from "@/contexts/VisibleSectionContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

export const AppProviders = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <VisibleSectionContextProvider>
        <App />
      </VisibleSectionContextProvider>
    </QueryClientProvider>
  );
};
