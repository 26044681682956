import { FC } from "react";
import { cn } from "../../utils";
import { PropsWithClassNameAndChildren } from "../../utils/types";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "./Dialog";

type Props = PropsWithClassNameAndChildren<{
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: string;
  closeIconClassName?: string;
}>;

export const Modal: FC<Props> = ({
  title,
  children,
  open,
  setOpen,
  className,
  closeIconClassName,
}) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className={cn(
          "flex lg:max-w-[70%] max-w-[90%] flex-col justify-between overflow-hidden",
          className
        )}
      >
        <div className="flex flex-col gap-6">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <DialogClose className={closeIconClassName} />
          </DialogHeader>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};
