import { Technology, TechnologyDTO } from "@/domain/Technology";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchTechnologies = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: Technology[]) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<
    StoryblokMultiStoryResult<TechnologyDTO>,
    Error,
    Technology[]
  >({
    queryKey: [QueryKeys.useFetchTechnologies],
    queryFn: async () => {
      return storyblokServiceSingleton.get<TechnologyDTO>(
        storyblokRoutes.technologies
      );
    },
    select: (data) => {
      const content = data?.data?.stories.map((story) => story.content);
      const technologies = content.map(Technology.create);

      technologies.sort((a, b) => (a.order ?? 100) - (b.order ?? 100));

      params?.onSuccess?.(technologies);

      return technologies;
    },
    ...params?.queryConfig,
  });
};
