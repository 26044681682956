import { MediaItemType, MediaItemTypeDTO } from "./MediaItemType";
import { StoryblokAssetDto } from "./Storyblok";

export class SidebarCardType {
  title: string;
  job: string;
  email: string;
  description: string;
  rights: string;
  buttonLabel: string;
  buttonIcon: StoryblokAssetDto;
  image: StoryblokAssetDto;
  mediaItems: MediaItemType[];

  private constructor(params: SidebarCardTypeDTO) {
    this.title = params.title;
    this.job = params.job;
    this.email = params.email;
    this.description = params.description;
    this.rights = params.rights;
    this.buttonLabel = params.buttonLabel;
    this.buttonIcon = params.buttonIcon;
    this.image = params.image;
    this.mediaItems = params.medias.map(MediaItemType.create);
  }

  static create(params: SidebarCardTypeDTO) {
    return new SidebarCardType(params);
  }
}

export type SidebarCardTypeDTO = {
  title: string;
  job: string;
  email: string;
  description: string;
  rights: string;
  buttonLabel: string;
  buttonIcon: StoryblokAssetDto;
  image: StoryblokAssetDto;
  medias: MediaItemTypeDTO[];
};
