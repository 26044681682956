import { MediaItemType } from "@/domain";
import { cn } from "@/utils";
import { PropsWithClassName } from "@/utils/types";
import { FC } from "react";
import { ReactSVG } from "react-svg";

type Props = {
  mediaItem: MediaItemType;
  iconClassName?: string;
};

export const SocialMediaItem: FC<PropsWithClassName<Props>> = ({
  mediaItem,
  className,
  iconClassName,
}) => {
  return (
    <a
      href={mediaItem.link}
      className={cn(
        "rounded-full p-4 border border-peat-700 flex transition-all duration-300 ease-in-out hover:border-secondary hover:fill-secondary fill-peat-700 hover:text-secondary text-peat-700",
        className
      )}
      target="_blank"
    >
      <ReactSVG
        src={mediaItem.icon.filename.replace(
          "//a.storyblok.com",
          "//a2.storyblok.com"
        )}
        className={cn(
          "h-5 w-5 hover:border-secondary hover:fill-secondary hover:text-secondary",
          iconClassName
        )}
        wrapper="span"
      />
    </a>
  );
};
