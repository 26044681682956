import { Project, ProjectDTO } from "@/domain/Project";
import { QueryConfig } from "@/services/queryConfig";
import { QueryKeys } from "@/services/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRelations,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "@/services/storyblok.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchProjects = (params?: {
  queryConfig?: QueryConfig;
  onSuccess?: (data: Project[]) => void;
  onError?: (error: any) => void;
}) => {
  return useQuery<StoryblokMultiStoryResult<ProjectDTO>, Error, Project[]>({
    queryKey: [QueryKeys.useFetchProjects],
    queryFn: async () => {
      return storyblokServiceSingleton.get<ProjectDTO>(
        storyblokRoutes.projects,
        {
          resolve_relations: [storyblokRelations.projectsKeywords],
        }
      );
    },
    select: (data) => {
      const content = data?.data?.stories.map((story) => story.content);
      const projects = content.map(Project.create);

      projects.sort((a, b) => a.order - b.order);

      params?.onSuccess?.(projects);

      return projects;
    },
    ...params?.queryConfig,
  });
};
