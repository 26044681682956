class LoggerService {
  private static instance: LoggerService;

  private constructor() {}

  public static getInstance(): LoggerService {
    if (!LoggerService.instance) {
      LoggerService.instance = new LoggerService();
    }
    return LoggerService.instance;
  }

  private getTimestamp(): string {
    const now = new Date();
    return now.toISOString();
  }

  public debug(message: string) {
    console.log(`[⚪⚪] ${this.getTimestamp()} - ${message}`);
  }

  public log(message: string): void {
    const decoratedMessage = `[🟢🟢] ${this.getTimestamp()} - ${message}`;
    console.log(decoratedMessage);
  }

  public error(message: string): void {
    const decoratedMessage = `[🔴🔴] ${this.getTimestamp()} - ${message}`;
    console.log(decoratedMessage);
  }

  public warn(message: string): void {
    const decoratedMessage = `[🟡🟡] ${this.getTimestamp()} - ${message}`;
    console.log(decoratedMessage);
  }
}

export const loggerService = LoggerService.getInstance();
